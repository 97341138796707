import { LogoPropsType } from "./types";

export function LogoXplus({ width = 120, height = 29, ...props }: LogoPropsType) {
  return (
    <svg
      width="120"
      height="29"
      viewBox="0 0 120 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8805 14.0849C27.8805 21.8602 21.6385 28.1699 13.9421 28.1699C6.24202 28.1699 0 21.864 0 14.0849C0 6.3059 6.24202 0 13.9421 0C21.6385 0 27.8805 6.3059 27.8805 14.0849Z"
        fill="#001466"
      />
      <path
        d="M7.16272 18.602L11.5483 14.0849L7.16272 9.56782L9.59038 7.14767L13.9309 11.6347L18.2864 7.14767L20.714 9.56782L16.3322 14.0849L20.714 18.602L18.2864 21.0259L13.9309 16.5539L9.59038 21.0259L7.16272 18.602Z"
        fill="white"
      />
      <path
        d="M51.7512 14.0849C51.7512 21.8602 45.5092 28.1699 37.8091 28.1699C30.109 28.1699 23.8707 21.864 23.8707 14.0849C23.8707 6.3059 30.1127 0 37.8091 0C45.5092 0 51.7512 6.3059 51.7512 14.0849Z"
        fill="#2C52BF"
      />
      <path
        d="M39.782 12.0782H44.938V15.8437H39.782V21.7287H35.8549V15.8437H30.684V12.0782H35.8549V6.43744H39.782V12.0782Z"
        fill="white"
      />
      <path
        d="M69.6655 6.40738H65.5092L63.5813 9.51899C63.3521 9.88352 63.0815 10.357 62.856 10.7854C62.5967 10.2781 62.2961 9.78957 62.127 9.51899L60.1954 6.40738H55.8286L60.5148 13.9534L55.5919 21.7362H59.7519L61.8714 18.3878C62.1157 17.9932 62.4351 17.4746 62.6944 16.9786C62.965 17.4972 63.2694 18.0196 63.4911 18.3841L65.5355 21.7324H69.906L65.0094 13.8557L69.6655 6.40738Z"
        fill="#1D2258"
      />
      <path
        d="M77.2754 6.40738H71.3979V21.74H75.2348V15.8399H77.2754C79.7632 15.8399 82.2848 14.2165 82.2848 11.1124C82.2848 8.02331 79.767 6.40738 77.2754 6.40738ZM78.4479 11.2026C78.4479 12.3112 77.5385 12.702 76.7606 12.702H75.2348V9.67683H76.7606C77.5723 9.68059 78.4479 10.1578 78.4479 11.2026Z"
        fill="#1D2258"
      />
      <path d="M87.7828 6.40738H83.7993V21.7362H93.4235V18.4141H87.7828V6.40738Z" fill="#1D2258" />
      <path
        d="M103.893 15.9301C103.893 17.5648 102.852 18.5833 101.172 18.5833C99.4663 18.5833 98.4479 17.5911 98.4479 15.9301V6.40738H94.4682V16.1857C94.4682 19.6468 97.204 22.0631 101.124 22.0631C105.054 22.0631 107.802 19.6468 107.802 16.1857V6.40738H103.893V15.9301Z"
        fill="#1D2258"
      />
      <path
        d="M115.287 11.9579C114.104 11.6197 113.623 11.2364 113.623 10.6389C113.623 9.93613 114.254 9.51524 115.314 9.51524C116.381 9.51524 117.55 9.96619 118.072 10.2668L118.271 10.3796L119.008 7.09509L118.899 7.03496C118.339 6.71178 116.9 6.16687 115.054 6.16687C111.905 6.16687 109.786 8.03835 109.786 10.823C109.786 13.2507 111.274 14.8854 114.213 15.6858C115.708 16.0917 116.017 16.6103 116.017 17.2642C116.017 18.0872 115.269 18.5983 114.07 18.5983C112.691 18.5983 111.112 18.0722 109.842 17.1928L109.542 16.9861L109.94 20.8418L110.011 20.8869C110.815 21.3754 112.424 21.9467 114.307 21.9467C117.926 21.9467 120 20.1616 120 17.0538C119.992 14.5096 118.455 12.8411 115.287 11.9579Z"
        fill="#1D2258"
      />
    </svg>
  );
}
