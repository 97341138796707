import { Grid, SxProps, Theme } from "@mui/material";

import { ReactNode } from "react";

type PageFooterProps = {
  children: ReactNode;
  sx?: SxProps<Theme>;
  id?: string;
};

export const PageFooter = ({ children, sx, ...rest }: PageFooterProps) => {
  const defaultSx: SxProps<Theme> = {
    pt: 3,
    pb: 3,
    px: 3,
    justifyContent: "end",
    position: "absolute",
    bottom: 0,
    left: 0,
  };
  const mergedSx: SxProps<Theme> = { ...defaultSx, ...sx };
  return (
    <Grid container sx={mergedSx} {...rest}>
      {children}
    </Grid>
  );
};
