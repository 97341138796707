import { LogoPropsType } from "./types";

export function LogoEA02({ width = 80, height = 65, ...props }: LogoPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1331_3300)">
        <path
          d="M0 48.9887V38.2696H6.5341V41.1432H3.47801V42.1524H5.92973V45.1115H3.47801V46.1207H6.93892V48.9944H0V48.9887Z"
          fill="#001466"
        />
        <path
          d="M14.6076 48.9887L13.4844 47.1584C13.3476 46.9247 13.148 46.5541 12.9998 46.2177H12.9656C12.8116 46.5541 12.6463 46.8563 12.4638 47.1584L11.3064 48.9887H7.66302L11.1068 43.5493L7.84548 38.2753H11.677L12.7033 39.923C12.8686 40.1739 13.0226 40.4761 13.1252 40.7441H13.1594C13.2792 40.4761 13.4274 40.1739 13.5985 39.923L14.6248 38.2753H18.2681L15.0068 43.498L18.4506 48.9887H14.6076Z"
          fill="#001466"
        />
        <path
          d="M19.4769 48.9887V38.2696H26.011V41.1432H22.9549V42.1524H25.4066V45.1115H22.9549V46.1207H26.4158V48.9944H19.4769V48.9887Z"
          fill="#001466"
        />
        <path
          d="M34.9569 41.6335C34.4722 41.3656 33.8679 41.1945 33.1609 41.1945C31.4162 41.1945 30.5267 42.4375 30.5267 43.6804C30.5267 44.9234 31.4333 46.2006 33.4003 46.2006C33.9363 46.2006 34.6946 46.0808 35.145 45.8642L34.8428 48.9031C34.3696 49.0856 33.5828 49.2053 32.7617 49.2053C29.8881 49.2053 26.9518 47.2896 26.9518 43.6291C26.9518 40.0029 29.8938 38.0529 32.7617 38.0529C33.9534 38.0529 35.031 38.2867 35.6525 38.6573L34.9569 41.6335Z"
          fill="#001466"
        />
        <path
          d="M41.605 49.2624C38.9823 49.2624 36.6845 47.7514 36.6845 45.0944V38.2753H40.2594V44.8778C40.2594 45.8357 41.0348 46.2063 41.6392 46.2063C42.2436 46.2063 43.019 45.8357 43.019 44.8949V38.2753H46.5312V45.0944C46.5255 47.7457 44.2278 49.2624 41.605 49.2624Z"
          fill="#001466"
        />
        <path
          d="M53.5956 41.166V48.9944H50.015V41.1489H47.2953V38.2753H56.2982V41.166H53.5956Z"
          fill="#001466"
        />
        <path d="M57.2333 48.9887V38.2696H60.8139V48.9887H57.2333Z" fill="#001466" />
        <path
          d="M68.9046 48.9887H65.1586L61.6122 38.2696H65.2213L66.6011 42.6199C66.8178 43.2927 67.0059 44.1138 67.12 44.8208H67.1542C67.2739 44.1138 67.4564 43.2927 67.673 42.6199L69.0528 38.2696H72.4282L68.9046 48.9887Z"
          fill="#001466"
        />
        <path
          d="M73.0668 48.9887V38.2696H79.6009V41.1432H76.5448V42.1524H78.9965V45.1115H76.5448V46.1207H80.0057V48.9944H73.0668V48.9887Z"
          fill="#001466"
        />
        <path
          d="M8.15908 64.0297L6.92182 60.9907H2.36049L1.14033 64.0297H0L4.4473 53.054H4.86922L9.31651 64.0297H8.15908ZM5.23983 56.7372C5.05167 56.2469 4.83501 55.6539 4.66396 55.0723H4.62975C4.4587 55.6482 4.24204 56.2412 4.05388 56.7372L2.7482 60.0613H6.5341L5.23983 56.7372Z"
          fill="#001466"
        />
        <path
          d="M15.1436 64.2178C12.6349 64.2178 10.9187 62.7411 10.9187 60.2837V53.2079H12.0533V60.181C12.0533 62.0626 13.2051 63.2029 15.1607 63.2029C17.1164 63.2029 18.2681 62.0683 18.2681 60.181V53.2079H19.3685V60.2837C19.3685 62.7411 17.658 64.2178 15.1436 64.2178Z"
          fill="#001466"
        />
        <path
          d="M25.5035 54.1943V64.0354H24.3689V54.1943H20.6514V53.2079H29.2381V54.1943H25.5035Z"
          fill="#001466"
        />
        <path
          d="M35.0025 64.2178C32.0148 64.2178 29.3351 61.9599 29.3351 58.6017C29.3351 55.2605 32.0148 53.0197 35.0025 53.0197C37.9902 53.0197 40.67 55.2605 40.67 58.6017C40.6643 61.9599 37.9845 64.2178 35.0025 64.2178ZM35.0025 54.0403C32.6306 54.0403 30.4925 55.8535 30.4925 58.6359C30.4925 61.4183 32.6306 63.2314 35.0025 63.2314C37.363 63.2314 39.5125 61.4126 39.5125 58.6359C39.5125 55.8535 37.3573 54.0403 35.0025 54.0403Z"
          fill="#001466"
        />
        <path
          d="M53.0768 64.0297L52.6377 58.4135C52.5693 57.4955 52.4838 56.5149 52.4496 55.5969H52.4154C52.0448 56.4978 51.4974 57.5982 51.0755 58.4819L48.3444 64.0981H47.9225L45.1914 58.4819C44.7865 57.6495 44.2734 56.5662 43.8686 55.614H43.8344C43.8002 56.532 43.7317 57.5982 43.6633 58.4135L43.2243 64.0297H42.1866L43.0532 53.2079H43.8002L46.7707 59.2631C47.2269 60.1981 47.7571 61.2986 48.179 62.302H48.2132C48.6238 61.3157 49.1141 60.2837 49.6044 59.2631L52.5579 53.2079H53.2877L54.1886 64.0297H53.0768Z"
          fill="#001466"
        />
        <path
          d="M63.6077 64.0297L62.3705 60.9907H57.8091L56.589 64.0297H55.4543L59.9016 53.054H60.3236L64.7709 64.0297H63.6077ZM60.6885 56.7372C60.5003 56.2469 60.2837 55.6539 60.1126 55.0723H60.0784C59.9073 55.6482 59.6907 56.2412 59.5025 56.7372L58.1968 60.0613H61.977L60.6885 56.7372Z"
          fill="#001466"
        />
        <path
          d="M68.9958 54.1943V64.0354H67.8612V54.1943H64.1437V53.2079H72.7304V54.1943H68.9958Z"
          fill="#001466"
        />
        <path
          d="M76.5448 64.1152C75.3075 64.1152 74.304 63.7275 73.7111 63.2998L73.5571 62.0626C74.3383 62.6898 75.3532 63.1288 76.5961 63.1288C77.9189 63.1288 78.8711 62.3477 78.8711 61.1788C78.8711 60.0442 78.2097 59.4113 76.7159 58.8697C74.8172 58.1912 73.9163 57.2732 73.9163 55.7965C73.9163 54.4395 74.9198 53.0996 77.0751 53.0996C78.0614 53.0996 78.9053 53.4189 79.35 53.7096L79.0934 54.6789C78.6886 54.371 77.8562 54.0518 77.0408 54.0518C75.7181 54.0518 75.0054 54.8842 75.0054 55.7337C75.0054 56.6517 75.6154 57.2447 76.9553 57.735C78.854 58.4306 80.0114 59.2973 80.0114 61.0933C80 62.9292 78.6601 64.1152 76.5448 64.1152Z"
          fill="#001466"
        />
        <path
          d="M45.8471 0L39.8147 6.03236L37.6823 3.90564L35.1393 6.44858L37.2661 8.581L39.8147 11.1239L42.3576 8.581L48.3957 2.54294L45.8471 0Z"
          fill="#2C52BF"
        />
        <path
          d="M48.8518 15.155L50.9786 13.0283L48.4356 10.4797L46.3032 12.6121L43.7603 15.155L46.3032 17.7037L52.307 23.7075L54.8557 21.1589L48.8518 15.155Z"
          fill="#30CEAF"
        />
        <path
          d="M44.7181 23.5479L42.5914 21.4211L40.0428 18.8725L37.4998 21.4211L31.4789 27.4364L34.0218 29.985L40.0428 23.9641L42.1752 26.0965L44.7181 23.5479Z"
          fill="#D1D1D1"
        />
        <path
          d="M36.1314 14.8072L36.1257 14.8015L33.5771 12.2529L27.5276 6.2034L24.9847 8.75204L31.0341 14.8015L28.9017 16.9282L31.4504 19.4769L33.5771 17.3444L33.5885 17.3501L36.1314 14.8072Z"
          fill="#001466"
        />
      </g>
      <defs>
        <clipPath id="clip0_1331_3300">
          <rect width="80" height="64.2178" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
