import { Paper, PaperProps } from "@mui/material";
import { PageContent } from "./PageContent";
import { PageContentLeft } from "./PageContentLeft";
import { PageContentRight } from "./PageContentRight";
import { PageFooter } from "./PageFooter";
import { PageHeader } from "./PageHeader";

const Page = ({ children, sx, ...rest }: PaperProps) => {
  return (
    <Paper
      sx={{
        flexGrow: 1,
        position: "relative",
        padding: "1.5rem",
        margin: "1.5rem",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
      elevation={2}
      {...rest}
    >
      {children}
    </Paper>
  );
};

export { Page, PageContent, PageContentLeft, PageContentRight, PageFooter, PageHeader };
