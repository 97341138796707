import { LogoPropsType } from "./types";

export function LogoEE({ width = 180, height = 18, ...props }: LogoPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 180 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8755 14.2737V3.52356H33.4304V6.40775H30.4151V7.45655H32.906V10.3407H30.4151V11.3895H33.8237V14.2737H26.8755Z"
        fill="#001466"
      />
      <path
        d="M41.5586 14.2738L40.3787 12.4384C40.2476 12.1762 39.9854 11.7829 39.8543 11.5207C39.7232 11.914 39.5921 12.1762 39.3299 12.4384L38.15 14.2738H34.4792L37.8878 8.89868L34.6103 3.65469H38.4122L39.461 5.35899C39.5921 5.62119 39.7232 5.88339 39.8543 6.14559C39.9854 5.88339 40.1165 5.62119 40.2476 5.35899L41.2964 3.65469H44.9672L41.6897 8.89868L45.0983 14.4049H41.5586V14.2738Z"
        fill="#001466"
      />
      <path
        d="M46.4093 14.2737V3.52356H52.9643V6.40775H49.949V7.45655H52.4399V10.3407H49.949V11.3895H53.3576V14.2737H46.4093Z"
        fill="#001466"
      />
      <path
        d="M61.879 6.93215C61.3546 6.66995 60.8302 6.53885 60.0436 6.53885C58.3394 6.53885 57.4217 7.71875 57.4217 9.02975C57.4217 10.2096 58.3394 11.5206 60.3058 11.5206C60.8302 11.5206 61.6168 11.3895 62.0101 11.1273L61.7479 14.2737C61.2235 14.4048 60.4369 14.5359 59.6504 14.5359C56.7662 14.5359 53.882 12.5694 53.882 9.02975C53.882 5.35896 56.7662 3.52356 59.6504 3.52356C60.8302 3.52356 61.879 3.78576 62.5345 4.17906L61.879 6.93215Z"
        fill="#001466"
      />
      <path
        d="M68.4341 14.5359C65.8121 14.5359 63.5834 12.9627 63.5834 10.3407V3.52356H67.1231V10.0785C67.1231 10.9962 67.9097 11.3895 68.4341 11.3895C69.0896 11.3895 69.7451 10.9962 69.7451 10.0785V3.52356H73.2848V10.3407C73.4159 13.0938 71.0561 14.5359 68.4341 14.5359Z"
        fill="#001466"
      />
      <path
        d="M80.3642 6.53889V14.4049H76.8245V6.53889H74.0714V3.65469H83.1173V6.53889H80.3642Z"
        fill="#001466"
      />
      <path d="M84.0349 14.2737V3.52356H87.5746V14.2737H84.0349Z" fill="#001466" />
      <path
        d="M95.7029 14.2737H91.901L88.3613 3.52356H91.901L93.212 7.84985C93.4742 8.50535 93.6053 9.29195 93.7364 10.0785C93.8675 9.42305 93.9986 8.50535 94.2608 7.84985L95.5718 3.52356H98.9804L95.7029 14.2737Z"
        fill="#001466"
      />
      <path
        d="M99.898 14.2737V3.52356H106.453V6.40775H103.438V7.45655H105.929V10.3407H103.438V11.3895H106.846V14.2737H99.898Z"
        fill="#001466"
      />
      <path
        d="M111.828 14.2737V3.52356H117.465V4.44126H112.877V8.24315H116.548V9.16085H112.877V13.0938H117.859V14.0115H111.828V14.2737Z"
        fill="#001466"
      />
      <path
        d="M125.856 14.2737L123.889 11.1273C123.627 10.6029 123.234 10.0785 122.972 9.55415C122.709 10.0785 122.316 10.6029 122.054 11.1273L120.087 14.2737H118.908L122.316 8.76755L119.039 3.52356H120.35L122.185 6.40775C122.447 6.80105 122.84 7.45655 123.103 7.84985C123.365 7.45655 123.627 6.80105 124.02 6.40775L125.856 3.52356H127.036L123.758 8.76755L127.167 14.2737H125.856Z"
        fill="#001466"
      />
      <path
        d="M132.149 9.42305H130.182V14.2737H129.002V3.52356H132.149C133.722 3.52356 135.295 4.57236 135.295 6.40775C135.295 8.37425 133.853 9.42305 132.149 9.42305ZM132.017 4.57236H130.182V8.37425H132.017C133.197 8.37425 134.115 7.71875 134.115 6.40775C134.115 5.35896 133.197 4.57236 132.017 4.57236Z"
        fill="#001466"
      />
      <path
        d="M137.13 14.2737V3.52356H138.31V13.2249H143.292V14.1426H137.13V14.2737Z"
        fill="#001466"
      />
      <path
        d="M148.929 14.5359C146.045 14.5359 143.292 12.3072 143.292 9.02975C143.292 5.75226 145.914 3.52356 148.929 3.52356C151.945 3.52356 154.567 5.75226 154.567 9.02975C154.567 12.3072 151.945 14.5359 148.929 14.5359ZM148.929 4.44126C146.57 4.44126 144.472 6.27666 144.472 9.02975C144.472 11.7828 146.57 13.6182 148.929 13.6182C151.289 13.6182 153.387 11.7828 153.387 9.02975C153.387 6.27666 151.289 4.44126 148.929 4.44126Z"
        fill="#001466"
      />
      <path
        d="M162.826 14.2737L159.286 9.42305H157.582V14.2737H156.402V3.52356H159.417C160.991 3.52356 162.564 4.57236 162.564 6.40775C162.564 7.84985 161.646 8.76755 160.466 9.16085L164.268 14.2737H162.826ZM159.155 4.57236H157.451V8.37425H159.155C160.335 8.37425 161.253 7.71875 161.253 6.40775C161.384 5.35896 160.335 4.57236 159.155 4.57236Z"
        fill="#001466"
      />
      <path
        d="M164.923 14.2737V3.52356H170.561V4.44126H166.103V8.24315H169.774V9.16085H166.103V13.0938H171.085V14.0115H164.923V14.2737Z"
        fill="#001466"
      />
      <path
        d="M178.689 14.2737L175.149 9.42305H173.445V14.2737H172.265V3.52356H175.149C176.723 3.52356 178.296 4.57236 178.296 6.40775C178.296 7.84985 177.378 8.76755 176.198 9.16085L180 14.2737H178.689ZM175.018 4.57236H173.314V8.37425H175.018C176.198 8.37425 177.116 7.71875 177.116 6.40775C177.247 5.35896 176.198 4.57236 175.018 4.57236Z"
        fill="#001466"
      />
      <path d="M14.8143 7.58765L17.0429 2.47476L14.8143 3.91686V7.58765Z" fill="#30CEAF" />
      <path
        d="M3.27747 4.96565C4.19517 3.78575 5.37507 2.99915 6.94826 2.60585C10.488 1.81925 13.8966 3.91685 14.8142 7.32544C14.8142 7.45654 14.8142 7.45654 14.8142 7.58764L16.1252 4.44125C14.1588 1.03265 10.2258 -0.671643 6.29276 0.246055C3.40857 0.901554 1.17987 2.99915 0.131073 5.62115L3.27747 4.96565Z"
        fill="#30CEAF"
      />
      <path
        d="M14.8143 7.32544C14.0277 3.78575 10.488 1.68815 6.94829 2.60585C5.37509 2.99915 4.19519 3.91685 3.2775 4.96565L4.45739 7.71874C4.85069 6.27664 5.89949 5.22785 7.47269 4.83455C9.04589 4.44125 10.7502 5.09675 11.6679 6.27664L14.8143 7.58764C14.8143 7.45654 14.8143 7.45654 14.8143 7.32544Z"
        fill="#001466"
      />
      <path d="M2.2287 10.2097L0 15.3225L2.2287 13.8804V10.2097Z" fill="#001466" />
      <path
        d="M13.7655 12.8317C12.8478 14.0115 11.6679 14.7981 10.0947 15.1914C6.55502 15.978 3.14642 13.8804 2.22872 10.4719C2.22872 10.3408 2.22872 10.3408 2.22872 10.2097L0.917725 13.356C2.75312 16.6335 6.68611 18.4689 10.6191 17.4201C13.5033 16.7646 15.732 14.667 16.7808 12.0451L13.7655 12.8317Z"
        fill="#001466"
      />
      <path
        d="M2.2287 10.4718C3.0153 14.0115 6.55499 16.1091 10.0947 15.1914C11.6679 14.7981 12.8478 13.8804 13.7655 12.8316L12.5856 10.0785C12.1923 11.5206 11.1435 12.5694 9.57029 12.9627C7.99709 13.356 6.29279 12.7005 5.37509 11.5206L2.2287 10.2096C2.2287 10.2096 2.2287 10.3407 2.2287 10.4718Z"
        fill="#30CEAF"
      />
    </svg>
  );
}
