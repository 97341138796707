import { LogoPropsType } from "./types";

export function LogoEE02({ width = 80, height = 55, ...props }: LogoPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 38.8036V28.0495H6.55738V30.9347H3.54098V31.9839H5.90164V34.8691H3.40984V35.9183H6.81967V38.8036H0Z"
        fill="#001466"
      />
      <path
        d="M14.6885 38.8035L13.5082 36.9675C13.377 36.7052 13.1147 36.3117 12.9836 36.0494C12.8524 36.4429 12.7213 36.7052 12.459 36.9675L11.2787 38.8035H7.60654L11.0164 33.4265L7.73768 28.1806H11.541L12.5901 29.8855C12.7213 30.1478 12.8524 30.4101 12.9836 30.6724C13.1147 30.4101 13.2459 30.1478 13.377 29.8855L14.4262 28.1806H18.0983L14.8197 33.4265L18.2295 38.9347H14.6885V38.8035Z"
        fill="#001466"
      />
      <path
        d="M19.541 38.8036V28.0495H26.0984V30.9347H22.9508V31.9839H25.4427V34.8691H22.9508V35.9183H26.3607V38.8036H19.541Z"
        fill="#001466"
      />
      <path
        d="M34.8853 31.4593C34.3607 31.197 33.8361 31.0658 33.0492 31.0658C31.3443 31.0658 30.4262 32.2462 30.4262 33.5576C30.4262 34.738 31.3443 36.0495 33.3115 36.0495C33.8361 36.0495 34.623 35.9183 35.0164 35.656L34.7541 38.6724C34.2295 38.8036 33.4426 38.9347 32.6557 38.9347C29.7705 38.9347 26.8853 36.9675 26.8853 33.4265C26.8853 29.7544 29.7705 27.9183 32.6557 27.9183C33.8361 27.9183 34.8853 28.1806 35.541 28.574L34.8853 31.4593Z"
        fill="#001466"
      />
      <path
        d="M41.5738 39.0658C38.9508 39.0658 36.7213 37.4921 36.7213 34.8691V28.0495H40.2623V34.6068C40.2623 35.5249 41.0492 35.9183 41.5738 35.9183C42.2295 35.9183 42.8852 35.5249 42.8852 34.6068V28.0495H46.5574V34.8691C46.5574 37.4921 44.1967 39.0658 41.5738 39.0658Z"
        fill="#001466"
      />
      <path
        d="M53.5082 30.9347V38.8036H49.9672V30.9347H47.2131V28.0495H56.2623V30.9347H53.5082Z"
        fill="#001466"
      />
      <path d="M57.1803 38.8036V28.0495H60.7213V38.8036H57.1803Z" fill="#001466" />
      <path
        d="M68.8525 38.8036H65.0492L61.5083 28.0495H65.0492L66.3607 32.3773C66.623 33.0331 66.7542 33.8199 66.8853 34.6068C67.0164 33.9511 67.1476 33.0331 67.4099 32.3773L68.7214 28.0495H72.1312L68.8525 38.8036Z"
        fill="#001466"
      />
      <path
        d="M73.0492 38.8036V28.0495H79.6066V30.9347H76.5902V31.9839H79.082V34.8691H76.5902V35.9183H80V38.8036H73.0492Z"
        fill="#001466"
      />
      <path
        d="M6.42627 54.0167V43.2626H12.0656V44.3117H7.6066V48.2462H11.1476V49.1642H7.47545V53.0986H12.4591V54.0167H6.42627Z"
        fill="#001466"
      />
      <path
        d="M20.459 54.0167L18.4918 50.8691C18.2295 50.3445 17.8361 49.8199 17.5738 49.2954C17.3115 49.8199 16.918 50.3445 16.6557 50.8691L14.6885 54.0167H13.5082L16.918 48.5085L13.6393 43.2626H14.9508L16.7869 46.1478C17.0492 46.5413 17.4426 47.197 17.7049 47.5904C17.9672 47.197 18.2295 46.5413 18.6229 46.1478L20.459 43.2626H21.6393L18.3606 48.5085L21.7705 54.0167H20.459Z"
        fill="#001466"
      />
      <path
        d="M26.7541 49.1642H24.7869V54.0167H23.6066V43.2626H26.7541C28.3279 43.2626 29.9016 44.3117 29.9016 46.1478C29.9016 48.115 28.459 49.1642 26.7541 49.1642ZM26.623 44.3117H24.7869V48.2462H26.623C27.8033 48.2462 28.7213 47.5904 28.7213 46.279C28.7213 45.0986 27.8033 44.3117 26.623 44.3117Z"
        fill="#001466"
      />
      <path
        d="M31.7377 54.0167V43.2626H32.9181V52.9675H37.9017V53.8855H31.7377V54.0167Z"
        fill="#001466"
      />
      <path
        d="M43.541 54.279C40.5246 54.279 37.9017 52.0495 37.9017 48.7708C37.9017 45.4921 40.5246 43.2626 43.541 43.2626C46.5574 43.2626 49.1803 45.4921 49.1803 48.7708C49.1803 52.0495 46.5574 54.279 43.541 54.279ZM43.541 44.1806C41.1803 44.1806 39.082 46.0167 39.082 48.7708C39.082 51.5249 41.1803 53.3609 43.541 53.3609C45.9017 53.3609 48 51.5249 48 48.7708C48 46.0167 45.9017 44.1806 43.541 44.1806Z"
        fill="#001466"
      />
      <path
        d="M57.4426 54.0167L53.9016 49.1642H52.1967V54.0167H51.0164V43.2626H54.0328C55.6066 43.2626 57.1803 44.3117 57.1803 46.1478C57.1803 47.5904 56.2623 48.5085 55.082 48.9019L58.8853 54.0167H57.4426ZM53.7705 44.3117H52.0656V48.2462H53.7705C54.9508 48.2462 55.8689 47.5904 55.8689 46.279C56 45.0986 54.9508 44.3117 53.7705 44.3117Z"
        fill="#001466"
      />
      <path
        d="M59.541 54.0167V43.2626H65.1804V44.3117H60.7213V48.2462H64.3935V49.1642H60.7213V53.0986H65.7049V54.0167H59.541Z"
        fill="#001466"
      />
      <path
        d="M73.3115 54.0167L69.7705 49.1642H68.0656V54.0167H66.8853V43.2626H69.9017C71.4754 43.2626 73.0492 44.3117 73.0492 46.1478C73.0492 47.5904 72.1312 48.5085 70.9508 48.9019L74.7541 54.0167H73.3115ZM69.6394 44.3117H67.9344V48.2462H69.6394C70.8197 48.2462 71.7377 47.5904 71.7377 46.279C71.8689 45.0986 70.8197 44.3117 69.6394 44.3117Z"
        fill="#001466"
      />
      <path d="M46.0329 7.59038L48.2624 2.47563L46.0329 3.91825V7.59038Z" fill="#30CEAF" />
      <path
        d="M34.4918 4.96746C35.4099 3.78713 36.5902 3.00024 38.164 2.6068C41.705 1.81992 45.1148 3.91828 46.0328 7.32811C46.0328 7.45926 46.0328 7.45926 46.0328 7.59041L47.3443 4.44287C45.3771 1.03303 41.4427 -0.671888 37.5082 0.246145C34.623 0.901882 32.3935 3.00024 31.3443 5.62319L34.4918 4.96746Z"
        fill="#30CEAF"
      />
      <path
        d="M45.9017 7.3281C45.1148 3.78712 41.5738 1.68876 38.0328 2.60679C36.4591 3.00023 35.2787 3.91827 34.3607 4.96745L35.541 7.72155C35.9345 6.27892 36.9837 5.22974 38.5574 4.8363C40.1312 4.44286 41.8361 5.09859 42.7541 6.27892L45.9017 7.5904C46.0328 7.5904 45.9017 7.45925 45.9017 7.3281Z"
        fill="#2C52BF"
      />
      <path d="M33.3115 10.2134L31.082 15.3281L33.3115 13.8855V10.2134Z" fill="#2C52BF" />
      <path
        d="M44.8525 12.8363C43.9345 14.0166 42.7541 14.8035 41.1804 15.197C37.6394 15.9838 34.2296 13.8855 33.3115 10.4757C33.3115 10.3445 33.3115 10.3445 33.3115 10.2134L32 13.3609C33.9673 16.7707 37.9017 18.4757 41.8361 17.5576C44.7214 16.9019 46.9509 14.8035 48 12.1806L44.8525 12.8363Z"
        fill="#2C52BF"
      />
      <path
        d="M33.3115 10.4756C34.0984 14.0166 37.6394 16.115 41.1804 15.197C42.7541 14.8035 43.9345 13.8855 44.8525 12.8363L43.6722 10.0822C43.2787 11.5248 42.2295 12.574 40.6558 12.9674C39.082 13.3609 37.3771 12.7052 36.459 11.5248L33.3115 10.2133C33.3115 10.3445 33.3115 10.3445 33.3115 10.4756Z"
        fill="#30CEAF"
      />
    </svg>
  );
}
