import { LogoPropsType } from "../brandingIcons/types";

export function ReportIssueIcon({ width = 40, height = 26, ...props }: LogoPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_1580_61125)">
        <path
          d="M23.7097 11.9193L13.0353 1.2449L12.001 0.210571L3.96561 8.24598L0.291203 11.9193C0.107668 12.1038 0.00463867 12.3534 0.00463867 12.6136C0.00463867 12.8739 0.107668 13.1235 0.291203 13.308L7.63226 20.649L12.001 25.0167L20.0353 16.9813L20.1605 16.8573L23.7097 13.3135C23.8017 13.2222 23.8747 13.1136 23.9245 12.994C23.9743 12.8743 24 12.746 24 12.6164C24 12.4868 23.9743 12.3585 23.9245 12.2388C23.8747 12.1192 23.8017 12.0106 23.7097 11.9193ZM12.001 16.2814L8.33326 12.6136L12.001 8.94587L15.6677 12.6136L12.001 16.2814Z"
          fill="#B9B9BA"
        />
        <path
          d="M12.0011 8.94588C10.8476 7.79263 10.1973 6.22977 10.1923 4.59866C10.1873 2.96755 10.828 1.40074 11.9745 0.240479L3.94904 8.2626L8.31669 12.6303L12.0011 8.94588Z"
          fill="url(#paint0_linear_1580_61125)"
        />
        <path
          d="M15.6777 12.6036L12.001 16.2814C13.1589 17.4396 13.8094 19.0102 13.8094 20.6479C13.8094 22.2856 13.1589 23.8563 12.001 25.0145L20.0486 16.9713L15.6777 12.6036Z"
          fill="url(#paint1_linear_1580_61125)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1580_61125"
          x1="11.3444"
          y1="5.23493"
          x2="6.22256"
          y2="10.3556"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stop-color="#5D5D5F" />
          <stop offset="1" stop-color="#A2A2A3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1580_61125"
          x1="12.7009"
          y1="19.948"
          x2="17.8128"
          y2="14.8373"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stop-color="#5D5D5F" />
          <stop offset="1" stop-color="#A2A2A3" />
        </linearGradient>
        <clipPath id="clip0_1580_61125">
          <rect width="24" height="25.1046" fill="white" transform="translate(0 0.0612793)" />
        </clipPath>
      </defs>
    </svg>
  );
}
