import { Grid, SxProps, Theme } from "@mui/material";

import { ReactNode } from "react";

type PageHeaderProps = {
  children: ReactNode;
  sx?: SxProps<Theme>;
};

export const PageHeader = ({ children, sx }: PageHeaderProps) => {
  return (
    <Grid item xs={12} sx={sx}>
      {children}
    </Grid>
  );
};
