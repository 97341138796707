import { LogoPropsType } from "./types";

export function LogoEA({ width = 181, height = 33, textColor = "white", ...props }: LogoPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 181 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1331_3299)">
        <path
          d="M30.9738 19.5411V10.06H36.7541V12.6028H34.0502V13.494H36.2204V16.1122H34.0502V17.0034H37.1116V19.5462H30.9738V19.5411Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M43.904 19.5411L42.9071 17.9198C42.7862 17.7134 42.61 17.3861 42.474 17.089H42.4438C42.3079 17.3861 42.1619 17.653 41.9957 17.9198L40.9685 19.5411H37.741L40.7873 14.7276L37.9022 10.06H41.2908L42.1971 11.5152C42.3431 11.7367 42.4791 12.0086 42.5697 12.2453H42.5999C42.7057 12.0086 42.8366 11.7418 42.9876 11.5152L43.8939 10.06H47.1215L44.2363 14.6823L47.2826 19.5411H43.904Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M48.209 19.5411V10.06H53.9893V12.6028H51.2855V13.494H53.4556V16.1122H51.2855V17.0034H54.3468V19.5462H48.209V19.5411Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M61.9099 13.0358C61.4769 12.7991 60.9432 12.6481 60.3188 12.6481C58.7731 12.6481 57.9876 13.7508 57.9876 14.8484C57.9876 15.9511 58.7882 17.079 60.5303 17.079C61.0036 17.079 61.6733 16.9732 62.0761 16.7819L61.8092 19.4706C61.3913 19.6318 60.6965 19.7375 59.9664 19.7375C57.4236 19.7375 54.8255 18.0457 54.8255 14.8031C54.8255 11.5907 57.4287 9.86868 59.9664 9.86868C61.0238 9.86868 61.9754 10.0751 62.5242 10.4024L61.9099 13.0358Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M67.7907 19.7828C65.4745 19.7828 63.4353 18.4435 63.4353 16.0971V10.06H66.6024V15.9008C66.6024 16.7467 67.2872 17.0739 67.8209 17.0739C68.3546 17.0739 69.0394 16.7467 69.0394 15.9159V10.06H72.146V16.0971C72.146 18.4435 70.1068 19.7828 67.7907 19.7828Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M78.3945 12.6179V19.5411H75.2274V12.6028H72.8206V10.06H80.7861V12.6179H78.3945Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M81.6169 19.5411V10.06H84.784V19.5411H81.6169Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M91.9439 19.5411H88.6308L85.4939 10.06H88.6912L89.9097 13.9119C90.101 14.506 90.2672 15.2361 90.3729 15.8605H90.4031C90.5089 15.2361 90.67 14.506 90.8663 13.9119L92.0848 10.06H95.0707L91.9439 19.5411Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M95.6245 19.5411V10.06H101.405V12.6028H98.701V13.494H100.871V16.1122H98.701V17.0034H101.762V19.5462H95.6245V19.5411Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M111.938 19.5664L110.856 16.9079H106.858L105.786 19.5664H104.789L108.681 9.94937H109.053L112.945 19.5664H111.938ZM109.381 13.1769C109.219 12.7439 109.023 12.2252 108.877 11.7217H108.847C108.701 12.2252 108.505 12.7489 108.343 13.1769L107.2 16.0872H110.514L109.381 13.1769Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M118.056 19.7276C115.856 19.7276 114.355 18.4335 114.355 16.2785V10.0853H115.352V16.1929C115.352 17.8444 116.364 18.8363 118.071 18.8363C119.778 18.8363 120.79 17.8394 120.79 16.1929V10.0853H121.757V16.2835C121.757 18.4335 120.256 19.7276 118.056 19.7276Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M127.129 10.9463V19.5664H126.132V10.9463H122.88V10.0853H130.402V10.9463H127.129Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M135.452 19.7275C132.834 19.7275 130.488 17.7487 130.488 14.8082C130.488 11.8778 132.834 9.91913 135.452 9.91913C138.071 9.91913 140.417 11.8828 140.417 14.8082C140.417 17.7538 138.066 19.7275 135.452 19.7275ZM135.452 10.8103C133.373 10.8103 131.5 12.4014 131.5 14.8384C131.5 17.2754 133.373 18.8665 135.452 18.8665C137.517 18.8665 139.405 17.2754 139.405 14.8384C139.405 12.4014 137.517 10.8103 135.452 10.8103Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M151.288 19.5664L150.9 14.6471C150.84 13.8415 150.764 12.9805 150.739 12.1799H150.709C150.382 12.9654 149.908 13.9322 149.536 14.7076L147.144 19.6269H146.771L144.38 14.7076C144.022 13.9775 143.579 13.0258 143.222 12.195H143.191C143.161 12.9956 143.101 13.9322 143.045 14.6471L142.658 19.5664H141.751L142.507 10.0853H143.161L145.764 15.3923C146.167 16.208 146.625 17.1748 146.998 18.0509H147.028C147.386 17.1899 147.814 16.2835 148.247 15.3923L150.835 10.0853H151.474L152.26 19.5664H151.288Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M160.512 19.5664L159.43 16.9079H155.432L154.359 19.5664H153.362L157.255 9.94934H157.627L161.519 19.5664H160.512ZM157.954 13.1768C157.788 12.7438 157.597 12.2252 157.451 11.7217H157.421C157.275 12.2252 157.078 12.7489 156.917 13.1768L155.774 16.0871H159.087L157.954 13.1768Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M165.235 10.9463V19.5664H164.238V10.9463H160.986V10.0853H168.508V10.9463H165.235Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M171.846 19.6419C170.763 19.6419 169.882 19.2995 169.364 18.9269L169.228 17.8443C169.913 18.3932 170.804 18.7809 171.886 18.7809C173.044 18.7809 173.88 18.0961 173.88 17.074C173.88 16.077 173.301 15.5282 171.992 15.0498C170.325 14.4557 169.54 13.6551 169.54 12.3611C169.54 11.1728 170.416 9.99963 172.304 9.99963C173.165 9.99963 173.91 10.2816 174.293 10.5334L174.072 11.3793C173.714 11.1124 172.989 10.8304 172.274 10.8304C171.116 10.8304 170.492 11.5605 170.492 12.3007C170.492 13.1013 171.025 13.6249 172.204 14.0529C173.87 14.6621 174.877 15.4224 174.877 16.9984C174.877 18.5996 173.704 19.6419 171.846 19.6419Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
        <path
          d="M18.1594 3.40363L12.8272 8.73581L10.9441 6.85268L8.69336 9.10337L10.5765 10.9865L12.8272 13.2372L15.0779 10.9865L20.4101 5.65432L18.1594 3.40363Z"
          fill="#2C52BF"
        />
        <path
          d="M20.8178 16.802L22.7009 14.9189L20.4502 12.6682L18.5671 14.5513L16.3164 16.802L18.5671 19.0527L23.8741 24.3597L26.1198 22.109L20.8178 16.802Z"
          fill="#30CEAF"
        />
        <path
          d="M17.1628 24.2187L15.2796 22.3356L13.029 20.0849L10.7783 22.3356L5.46118 27.6577L7.71188 29.9084L13.029 24.5863L14.9121 26.4694L17.1628 24.2187Z"
          fill="#D1D1D1"
        />
        <path
          d="M9.57466 16.4949L9.56962 16.4848L7.31893 14.2341L1.97164 8.88684L-0.279053 11.1375L5.06823 16.4848L3.1851 18.368L5.4358 20.6186L7.31893 18.7355L7.32396 18.7406L9.57466 16.4949Z"
          fill={textColor !== "original" ? textColor : "#001466"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1331_3299">
          <rect width="181" height="33" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
