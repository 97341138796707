import { LogoPropsType } from "./types";

export function LogoEA03({ width = 36, height = 37, ...props }: LogoPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1331_3301)">
        <path
          d="M25.1456 0L17.8739 7.27173L15.3054 4.70429L12.2374 7.77226L14.8059 10.3407L17.8739 13.4087L20.9418 10.3407L28.2146 3.06797L25.1456 0Z"
          fill="#152659"
        />
        <path
          d="M28.7647 18.269L31.3332 15.7005L28.2652 12.6326L25.6968 15.2L22.6288 18.269L25.6968 21.337L32.932 28.5722L36 25.5043L28.7647 18.269Z"
          fill="#30CEAF"
        />
        <path
          d="M23.7859 28.3838L21.2184 25.8153L18.1494 22.7473L15.0815 25.8153L7.82697 33.0698L10.8949 36.1388L18.1494 28.8843L20.7179 31.4517L23.7859 28.3838Z"
          fill="#D1D1D1"
        />
        <path
          d="M13.436 17.8465L13.4269 17.8364L10.359 14.7684L3.06797 7.47742L0 10.5464L7.29099 17.8364L4.72252 20.4048L7.79049 23.4728L10.359 20.9054L10.3681 20.9145L13.436 17.8465Z"
          fill="#001466"
        />
      </g>
      <defs>
        <clipPath id="clip0_1331_3301">
          <rect width="36" height="36.1388" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
