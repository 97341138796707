import { Box, Typography } from "./materialUI";

export const Info = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={"100%"}
    >
      <Typography variant="h4" color="neutral300">
        {children}
      </Typography>
    </Box>
  );
};
