import { Grid, GridProps } from "@mui/material";
import { Children, ReactNode, cloneElement, isValidElement } from "react";

type PageContentProps = GridProps & {
  children: ReactNode;
};

export const PageContent = ({ children, sx, ...rest }: PageContentProps) => {
  const childArray = Children.toArray(children);
  const isSplitLayout = childArray.length > 1;

  return (
    <Grid
      sx={{
        display: "flex",
        flexGrow: 1,
        overflow: "scroll",
        ...sx,
      }}
      {...rest}
      container
    >
      {childArray.map((child, index) =>
        isValidElement(child) ? (
          isSplitLayout ? (
            <Grid container xs={6} key={index} flexGrow={"1"} height={"100%"}>
              {cloneElement(child)}
            </Grid>
          ) : (
            cloneElement(child)
          )
        ) : null,
      )}
    </Grid>
  );
};
