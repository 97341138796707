import { LogoPropsType } from "./types";

export function LogoEE03({ width = 36, height = 38, ...props }: LogoPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.4823 13.184L36 5.17564L32.7069 7.27128C28.3659 1.58313 20.9563 -1.26095 13.5468 0.53531C7.40957 2.03219 2.76923 6.37315 0.598755 11.8368L6.9605 10.3399H7.33472C9.13098 7.87003 11.4511 7.34612 14.5946 6.59768C21.9293 4.80142 28.2911 7.79518 30.0125 15.1299C30.0125 15.2796 31.21 15.6538 31.21 15.8035V15.8783L32.4823 13.184Z"
        fill="#30CEAF"
      />
      <path
        d="M31.21 15.3544C29.4137 8.01972 22.079 3.52907 14.7443 5.32533C11.526 6.07377 8.83163 7.94487 7.03537 10.3399L9.65491 16.2526C10.4782 13.3336 12.7984 10.9386 15.9418 10.1154C19.3098 9.29207 22.7526 10.5644 24.7734 13.184L31.3597 15.8783C31.2848 15.6538 31.2848 15.5041 31.21 15.3544Z"
        fill="#2C52BF"
      />
      <path
        d="M28.8898 26.8056L27.6175 26.2817C25.8212 28.7515 23.8753 29.7245 20.657 30.5478C13.3222 32.344 9.05613 30.0987 7.33472 22.764C7.33472 22.6143 4.64033 21.5665 4.64033 21.4168V21.342L3.7422 23.3628L0 32.1195L3.29314 30.0239C7.6341 35.712 15.0437 38.5561 22.4532 36.7598C28.5904 35.263 33.2308 30.922 35.4012 25.4584L28.8898 26.8056Z"
        fill="#2C52BF"
      />
      <path
        d="M4.71518 21.791C6.51144 29.1257 13.8462 33.6164 21.1809 31.8201C24.3992 31.0717 27.0936 29.2006 28.8898 26.8056L26.2703 20.8929C25.447 23.8118 23.1268 26.2068 19.9834 27.0301C16.6154 27.8534 13.1726 26.581 11.1518 23.9615L4.56549 21.2671C4.64033 21.4917 4.64033 21.6413 4.71518 21.791Z"
        fill="#30CEAF"
      />
    </svg>
  );
}
