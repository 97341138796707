import { LogoPropsType } from "../brandingIcons/types";

export function ConfluenceBaseIcon({ width = 40, height = 24, ...props }: LogoPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.870299 17.6051C0.622086 18.0099 0.343324 18.4796 0.106567 18.8538C-0.105349 19.212 0.00841571 19.6738 0.362417 19.8925L5.32668 22.9474C5.50093 23.055 5.7111 23.0881 5.90998 23.0393C6.10887 22.9905 6.27982 22.8638 6.38444 22.6878C6.58301 22.3556 6.83887 21.924 7.11763 21.462C9.08424 18.2161 11.0623 18.6133 14.6289 20.3164L19.5512 22.6572C19.7376 22.746 19.952 22.7554 20.1455 22.6835C20.3391 22.6116 20.4952 22.4643 20.5784 22.2754L22.9422 16.9292C23.1092 16.5474 22.9392 16.1022 22.5603 15.9288C21.5216 15.44 19.4557 14.4662 17.596 13.5688C10.9057 10.3191 5.21975 10.5292 0.870299 17.6051Z"
        fill="#B9B9BA"
      />
      <path
        d="M23.1297 5.47259C23.3779 5.06781 23.6567 4.59812 23.8934 4.22389C24.1054 3.86578 23.9916 3.40393 23.6376 3.18521L18.6733 0.130286C18.4977 0.011962 18.2807 -0.0280919 18.0744 0.0196941C17.868 0.0674801 17.6908 0.198814 17.585 0.382318C17.3864 0.714542 17.1306 1.14605 16.8518 1.60811C14.8852 4.85397 12.9072 4.45683 9.34052 2.75371L4.43355 0.424323C4.24712 0.335591 4.03272 0.326116 3.83919 0.398058C3.64567 0.47 3.48952 0.617229 3.40633 0.806189L1.04257 6.15231C0.875572 6.53414 1.0455 6.97935 1.42444 7.1528C2.46312 7.64159 4.52901 8.61535 6.3887 9.51274C13.0943 12.7586 18.7803 12.5409 23.1297 5.47259Z"
        fill="#B9B9BA"
      />
    </svg>
  );
}
