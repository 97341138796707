import { LogoPropsType } from "./types";

export function LogoXplusWhite({ width = 120, height = 29, ...props }: LogoPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 120 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.6876 6.3407H65.5331L63.6061 9.45095C63.377 9.81531 63.1065 10.2886 62.8811 10.7168C62.6219 10.2097 62.3214 9.7214 62.1524 9.45095L60.2216 6.3407H55.8568L60.5409 13.8834L55.6201 21.6628H59.7784L61.897 18.3159C62.1411 17.9215 62.4604 17.4031 62.7196 16.9073C62.9901 17.4257 63.2943 17.9478 63.5159 18.3121L65.5594 21.659H69.928L65.0335 13.7858L69.6876 6.3407Z"
        fill="white"
      />
      <path
        d="M77.2942 6.3407H71.4193V21.6666H75.2545V15.7691H77.2942C79.7809 15.7691 82.3014 14.1464 82.3014 11.0436C82.3014 7.95592 79.7847 6.3407 77.2942 6.3407ZM78.4662 11.1338C78.4662 12.2419 77.5572 12.6326 76.7796 12.6326H75.2545V9.60871H76.7796C77.591 9.61247 78.4662 10.0895 78.4662 11.1338Z"
        fill="white"
      />
      <path d="M87.7969 6.3407H83.8152V21.6628H93.4352V18.3422H87.7969V6.3407Z" fill="white" />
      <path
        d="M103.9 15.8593C103.9 17.4933 102.86 18.5112 101.181 18.5112C99.4754 18.5112 98.4574 17.5196 98.4574 15.8593V6.3407H94.4794V16.1147C94.4794 19.5743 97.2141 21.9896 101.132 21.9896C105.061 21.9896 107.807 19.5743 107.807 16.1147V6.3407H103.9V15.8593Z"
        fill="white"
      />
      <path
        d="M115.29 11.8888C114.106 11.5507 113.626 11.1676 113.626 10.5703C113.626 9.8679 114.257 9.44719 115.316 9.44719C116.383 9.44719 117.551 9.89795 118.073 10.1985L118.272 10.3112L119.008 7.02811L118.899 6.96801C118.34 6.64496 116.901 6.1003 115.057 6.1003C111.909 6.1003 109.79 7.97095 109.79 10.7544C109.79 13.181 111.278 14.815 114.215 15.6151C115.71 16.0208 116.018 16.5392 116.018 17.1928C116.018 18.0154 115.271 18.5263 114.073 18.5263C112.694 18.5263 111.116 18.0004 109.847 17.1214L109.546 16.9148L109.944 20.7688L110.016 20.8139C110.82 21.3022 112.427 21.8732 114.309 21.8732C117.927 21.8732 120 20.0889 120 16.9824C119.993 14.4394 118.456 12.7716 115.29 11.8888Z"
        fill="white"
      />
      <path
        d="M24.9947 5.55938C22.4516 2.18619 18.4474 0 13.9247 0C6.23552 0 0 6.29938 0 14.0675C0 21.8356 6.23552 28.135 13.9247 28.135C18.4436 28.135 22.4516 25.9488 24.9947 22.5756C23.3907 20.134 22.4516 17.2078 22.4516 14.0675C22.4516 10.9234 23.3907 8.001 24.9947 5.55938ZM20.6937 18.5789L18.2708 20.9979L13.921 16.5279L9.58618 20.9979L7.16334 18.5789L11.5432 14.0675L7.15583 9.55613L9.58242 7.13704L13.9172 11.6184L18.2671 7.13704L20.6899 9.55613L16.3138 14.0675L20.6937 18.5789Z"
        fill="white"
      />
      <path
        d="M37.77 0C30.0807 0 23.8452 6.29938 23.8452 14.0675C23.8452 21.8356 30.077 28.135 37.77 28.135C45.4629 28.135 51.6947 21.8356 51.6947 14.0675C51.6947 6.29938 45.4592 0 37.77 0ZM44.8882 15.8255H39.7383V21.7041H35.8167V15.8255H30.6517V12.0654H35.8167V6.43085H39.7383V12.0654H44.8882V15.8255Z"
        fill="white"
      />
    </svg>
  );
}
