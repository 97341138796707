import { LogoPropsType } from "./types";

export function LogoXplus02({ width = 50, height = 41, ...props }: LogoPropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9254 27.875H7.69882L6.20223 30.2906C6.02427 30.5735 5.81422 30.9411 5.63918 31.2737C5.43789 30.8799 5.2045 30.5006 5.07322 30.2906L3.57372 27.875H0.183791L3.82169 33.733L0 39.7748H3.22656L4.87193 37.1754C5.06156 36.8691 5.30953 36.4665 5.51082 36.0814C5.72087 36.484 5.95717 36.8895 6.1293 37.1725L7.71632 39.7719H11.1092L7.30789 33.6572L10.9254 27.875Z"
        fill="#001466"
      />
      <path
        d="M16.833 27.875H12.2703V39.7777H15.2489V35.1975H16.833C18.7642 35.1975 20.7218 33.9372 20.7218 31.5275C20.7247 29.1295 18.7671 27.875 16.833 27.875ZM17.7432 31.5975C17.7432 32.4581 17.0372 32.7615 16.4333 32.7615H15.2489V30.4131H16.4333C17.0634 30.416 17.7432 30.7865 17.7432 31.5975Z"
        fill="#001466"
      />
      <path d="M24.9898 27.875H21.9003V39.7748H29.3687V37.1988H24.9898V27.875Z" fill="#001466" />
      <path
        d="M37.4964 35.2675C37.4964 36.5365 36.6883 37.3271 35.3843 37.3271C34.0598 37.3271 33.2692 36.557 33.2692 35.2675V27.875H30.1797V35.4688C30.1797 38.1557 32.3036 40.0315 35.3463 40.0315C38.3978 40.0315 40.5304 38.1557 40.5304 35.4688V27.875H37.4964V35.2675Z"
        fill="#001466"
      />
      <path
        d="M46.3417 32.1839C45.4227 31.9213 45.0493 31.6238 45.0493 31.1599C45.0493 30.6144 45.5394 30.2876 46.3621 30.2876C47.1906 30.2876 48.0979 30.6377 48.5034 30.8711L48.658 30.9586L49.2298 28.4089L49.1452 28.3651C48.7106 28.1142 47.5932 27.6912 46.1608 27.6912C43.7161 27.6912 42.0707 29.1441 42.0707 31.3058C42.0707 33.1904 43.226 34.4594 45.5073 35.0808C46.6684 35.3959 46.9077 35.7985 46.9077 36.3061C46.9077 36.945 46.3271 37.3417 45.3965 37.3417C44.3258 37.3417 43.1005 36.9333 42.1145 36.2507L41.8811 36.0902L42.1903 39.0834L42.2458 39.1184C42.8701 39.4976 44.1187 39.9411 45.5803 39.9411C48.3896 39.9411 50 38.5553 50 36.1427C49.9971 34.1648 48.801 32.8695 46.3417 32.1839Z"
        fill="#001466"
      />
      <path
        d="M25.0627 10.9341C25.0627 16.9701 20.2171 21.8683 14.2424 21.8683C8.2648 21.8683 3.41913 16.973 3.41913 10.9341C3.41913 4.89527 8.2648 0 14.2424 0C20.22 0 25.0627 4.89527 25.0627 10.9341Z"
        fill="#001466"
      />
      <path
        d="M8.98248 14.4407L12.387 10.9341L8.98248 7.4275L10.8671 5.54874L14.2366 9.03203L17.6178 5.54874L19.5023 7.4275L16.1008 10.9341L19.5023 14.4407L17.6178 16.3224L14.2366 12.8508L10.8671 16.3224L8.98248 14.4407Z"
        fill="white"
      />
      <path
        d="M43.5965 10.9341C43.5965 16.9701 38.7508 21.8683 32.7732 21.8683C26.7956 21.8683 21.9529 16.973 21.9529 10.9341C21.9529 4.89527 26.7986 0 32.7732 0C38.7508 0 43.5965 4.89527 43.5965 10.9341Z"
        fill="#2C52BF"
      />
      <path
        d="M34.3048 9.37628H38.3074V12.2994H34.3048V16.868H31.2562V12.2994H27.242V9.37628H31.2562V4.99738H34.3048V9.37628Z"
        fill="white"
      />
    </svg>
  );
}
