import { SxProps } from "@mui/material";
import { DataTestIds } from "../dataTestIds";
import { Box, Button, Container, Typography } from "./materialUI";
import { PageContent } from "./Page";

interface NotFoundPageProps {
  title: string;
  subtitle: string;
  linkHref?: string;
  linkText?: string;
  sx?: SxProps;
}

export const NotFoundPage = ({ title, subtitle, linkHref, linkText, sx }: NotFoundPageProps) => {
  return (
    <PageContent>
      <Box
        data-testid={DataTestIds.NOT_FOUND}
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={8}
        sx={sx}
      >
        <Container maxWidth="sm" sx={{ textAlign: "center" }}>
          <Typography color="error" variant="h1" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {subtitle}
          </Typography>
          {linkHref && (
            <a href={linkHref}>
              <Button variant="outlined" color="secondary" sx={{ mt: 3 }}>
                {linkText}
              </Button>
            </a>
          )}
        </Container>
      </Box>
    </PageContent>
  );
};
