import { DataTestIds } from "../dataTestIds";
import { useTranslations } from "../translations/useTranslations";
import { CancelEditingDialog } from "./CancelEditingDialog";
import { FormSubmitButton } from "./forms";
import { ActionButtonsWrapper, Button, ButtonProps } from "./materialUI";
import { useOverlayStore } from "./OverlayManager";

export type ControlButtonsProps = {
  editable: boolean;
  onClose: () => void;
  disableEditing: () => void;
  formId: string;
  disabled: boolean;
  width?: string;
  additionalCancelButtonProps?: ButtonProps;
  additionalSaveButtonProps?: ButtonProps;
};

export const ControlButtons = ({
  editable,
  onClose,
  disableEditing,
  formId,
  disabled,
  additionalCancelButtonProps,
  additionalSaveButtonProps,
}: ControlButtonsProps) => {
  const { open } = useOverlayStore();
  const { t } = useTranslations("EditMode");

  if (!editable) {
    return null;
  }

  return (
    <ActionButtonsWrapper direction="row" justify="right" flat>
      <Button
        data-testid={DataTestIds.EDITABLEAREA_BUTTON_CANCEL}
        variant="outlined"
        onClick={() => {
          open(CancelEditingDialog, {
            disableEditing: disableEditing,
            onClose: onClose,
          });
        }}
        {...additionalCancelButtonProps}
      >
        {t("cancel")}
      </Button>

      <FormSubmitButton
        form={formId}
        disabled={disabled}
        dataTestid={DataTestIds.EDITABLEAREA_BUTTON_SAVE}
        {...additionalSaveButtonProps}
      >
        {t("save")}
      </FormSubmitButton>
    </ActionButtonsWrapper>
  );
};
